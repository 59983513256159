@import "../../../assets/css/global.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: $greyMediumDark;
  padding: 5px 0;
}
.navWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: 80%;
  margin: 0 auto;
}
.links {
  color: $white;
  font-size: 13px;
  font-weight: 500;
  transition: $animate;
  @include flex-center;
  gap: 5px;

  &:hover {
    color: $secondary;
    cursor: pointer;
    transition: $animate;
  }
}
.vLine {
  color: $white;

  &:hover {
    cursor: default;
  }
}
