@import "../../../assets/css/global.scss";

.mainContainer {
  width: 100%;
  height: 100%;
  background-color: darken($greyMediumDark, 8%);
}
.container {
  width: 80%;
  margin: 0 auto;
  @include flex-center;
  align-items: flex-start;
  gap: 150px;
  padding: 40px 0 70px;
}
.header {
  color: $greyBluish;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.content {
  color: $greyBluish;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 6px;
  transition: $animate;

  &:hover {
    color: $secondary;
    cursor: pointer;
    transition: $animate;
  }
}

.sectorContainer,
.locationsContainer,
.forInvestorsContainer {
  width: fit-content;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0, auto;
  display: flex;
  // align-items: center;
  flex-direction: column;
}
