.chartSection {
    row-gap: 5rem;
    column-gap: 5rem;
    color: #444;
    -webkit-column-gap: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    padding-bottom: 1rem;
}
