.constituency {
    grid-template-columns: 1fr 1fr;
    display: grid;
    row-gap: 30px;
    column-gap: 30px;
    margin-top: 3rem;
    width: 100%;
}
.allInfo1{
    align-self: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns:1fr;
    width:100%;
    margin: auto;
    margin-bottom: 1rem;
}
.allInfo2{
    align-self: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2,1fr);
    width:100%;
    margin: auto;
}
.allInfo3{
    align-self: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3,1fr);
    width:100%;
    margin: auto;
    margin-bottom: 1rem;
}

.allInfo4{
    align-self: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4,1fr);
    width:100%;
    margin: auto;
    margin-bottom: 1rem;
}

.createBtn {
    display: flex;
    align-items: center;
    justify-content: end;
}

