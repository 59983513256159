.labelClassName {
  padding: 5px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 10px;
  display: initial;
  margin-top: 4px;
}

.pos {
  position: absolute;
  left: 0px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #4dd64dd1;
}
.neg {
  position: absolute;
  right: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  color: red;
}

// .wrapper {
//   border: 1px solid black;
//   border-radius: 50%;
// }

// .container {
//   background-color: pink;
// }

.barCompleted {
  background-color: lightblue;
  // width: 80%;
}

// .label {
//   font-size: 10px;
//   color: white;
// }