.table-pagination {
    display: flex;
    align-items: center !important;
    justify-content: flex-end !important;
}

.table-input {
    display: "flex";
    align-items: "center";
}

.custom-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    gap:20px;
}

.custom-table-title {
    font-size: 1.5rem;
    font-weight: 500;
    width: 30%;
}

.custom-table {
    width: 100%;
    border-color: grey;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    border-spacing: 0;
    border-collapse: collapse;
}

.custom-table-head {
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: #717171;
    justify-content: center;
}
.custom-table-head:hover {
    // color: #0a467a;
}

.custom-table td,
.custom-table th {
    border: 1px solid #ddd;
    font-size: 15px;
    color: #717171;
    padding:11px;
    line-height: 1.42857143;
    vertical-align: top;
    border-radius: 12px;
    border: 4px solid white;
}

.custom-table tr td:last-child {
    // display: flex;
}

.custom-table tr:nth-child(even) {
    background-color: #f6f6f6;
}

.custom-table tr:hover {
    background-color: #e9e9e9;
}

.custom-table th {
    padding-top: 16px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f6f6f7;
    color: black;
    border-radius: 12px;
}

.custom-table th:hover {
    // color: #337ab7;
    background-color: #edeeef !important;
}


tr:hover td:first-child,
tr td:first-child {
    background: #f6f6f7;
}

.searchArea {
    display: flex;
    justify-content: end;
    align-items: center;
}

.search-btn {
    margin-left: 2px;
    height: 29px;
    border-radius: 5px;
    background-color: transparent;
    border: none;
    color: white;
    padding: 0px 10px;
}

.inputSearch {
    width: 65%;
}

.pagination {
    justify-content: center;
    display: flex;
    margin: 0px;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li {
    display: inline-block;
    width: 30px;
    // border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    font-size: 25px;
}

ul.pagination li a {
    text-decoration: none;
    color: #ee8710;
    font-size: 12px;
    padding: 7px;
}

ul.pagination li.active a {
    color: white;
}
ul.pagination li.active {
    background-color: #ee8710;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: #ee8710;
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #ee8710;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.page-item.active .page-link {
    background-color: #ee8710 !important;
    border-color:#ee8710 !important
}
