.allInfo {
    align-self: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 3rem;
    width: calc(100% - 3rem);
}

.cCard {
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    height: max-content;
    text-align: center;
}
