@import "../../../assets/css/global.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding: 2px 0px;
}
.navWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.rightImgWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.leftImgWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.leftImg {
  height: 60px;
}
.rightImg {
  height: 60px;
  &:first-child {
    margin-right: 20px;
  }
}

.loginBtn {
  @include button-primary-dark;
  height: 35px;
  margin-top: 2px;
  padding: 8px 20px;
  font-family: "Poppins";
  transition: $animate;
  font-size: 15px;
  font-weight: 500;
  @include flex-center;
  gap: 10px;

  &:hover {
    @include light-hover;
  }

  &:active {
    @include light-active;
  }
}

.title {
  font-size: 36px;
  font-weight: 700;
  color: $primary;
  @include flex-center;
  gap: 15px;

  & span {
    @include box(150px, 5px);
    background-color: $secondary;
  }
}
