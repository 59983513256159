@import "../../../assets/css/global.scss";

.listContainer {
  padding: 5px 0px !important;
  border-radius: 0px 0px 6px 6px;
}

.listContainerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: max-content;
}

.itemsAlt {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px;
  color: $primary;
  &:hover {
    cursor: pointer;
    background-color: $primary;
    color: $white;
  }
}

.items {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 36px;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: $primary;
    color: $white;
  }
}
.itemActive {
  text-align: left;
  background-color: $primary;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 36px;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: $primary;
    color: $white;
  }
}

.tippyGridAlt {
  background-color: $secondary !important;
  color: $primary !important;
  min-width: max-content !important;
  position: relative;
  border-radius: 0px 10px 10px 10px;
  right: 9.81px;
}
