@import "../../constituency/styles.module.scss";
@import "../../../../assets/css/common.scss";


.grid {
    width: 100%;
    font-size: 13px;
    border: 4px solid white;
    border-collapse: collapse;
    background-color: #fff;
    margin-bottom: 5px;
}
.grid th {
    padding: 5px 7px;
    color: #747474;
    border: 4px solid white;
    text-align: center;
    border-collapse: collapse;
    font-size: 14px;
    background-color: #e3e3e3;
}
.grid td {
    padding: 3px 7px;
    color: #000;
    border: 4px solid white;
    border-collapse: collapse;
    text-align: left;
    vertical-align: top;
    background: #f6f6f6;
}
.tal {
    text-align: left !important;
}
.tac {
    text-align: center !important;
}

.title {
    clear: both;
    font-size: 18px;
    font-weight: 600;
    color: #ff4800;
    background-color: none;
    text-align: center;
    text-transform: uppercase;
    margin-bottom:10px;
}

.eachBox {
    // background: #ff073a20;
    min-width: 26%;
    max-width: 26%;
    padding: 20px 20px;
    border-radius: 10px;
    font-size: 90%;
    background: linear-gradient(to top, #ffffff, #ffffff);
    box-shadow: 1px 1px 10px #999;
}

.eachSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: auto;
    font-size: 14px;
    background: #f1f1f1;
    margin: 0% 0.3% 10px 0.3%;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
    text-align: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    padding-top: 10px;
}
.eachSection2 {
    height: auto;
    font-size: 14px;
    background: #f1f1f1;
    margin: 0% 0.3% 10px 0.3%;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
    text-align: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    padding-top: 10px;
}
.section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 5px;
}

.removeSection{
    border-bottom: 0px solid lightgrey;
    margin-bottom: 0px;
}

.numberSpan {
    font-size: 1.5rem;
    font-weight: 700;
}

.nameSpan {
    font-size: 1.1em;
}

.spaceApart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
}