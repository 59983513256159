.infoCard {
    background: #ff073a20;
    padding: 20px 20px;
    border-radius: 10px;
}

.singleRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.title {
    font-size: 1rem;
    font-weight: 600;
    padding-right: 5px;
    padding: 4px;
    text-align: center;
}

.value {
    font-size: 1.2rem;
    align-items: center;
    display: flex;
    justify-content: center;
}
