.box_matter_container {
    width: 95%;
    text-transform: uppercase;
    margin: 5px auto;
    display: block;
    clear: both;
    height: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}
.top_party{
    width: 46%;
    box-sizing: border-box;
    height: auto;
    margin-bottom: 2%;
    position: relative;
    margin-right: 4%;
    background: #fff;
    background: linear-gradient(to top, #f1f1f1, #ffffff);
    box-shadow: 1px 1px 10px #ccc;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    border-bottom: 1px solid #999;
}
.party_symbol {
    width: 45px;
    height: 100%;
    position: absolute;
    margin-right: 2%;
    border-right: 1px solid #ccc;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    float: left;
    padding: 3% 2%;
    background: #f1f1f1;
}
.party_symbol img {
    width: 100%!important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    height: 68%;
    
}
.party_symbol span {
    width: 100%;
    clear: both;
    float: left;
    font-size: 75%;
}
.top_party_seats {
    width: 69%;
    float: right;
    text-align: left;
    padding: 2%;
    text-transform: none;
}
.top_party_seats_head {
    display: block;
    clear: both;
    border-bottom: 1px dotted #ccc;
    padding-bottom: 5px;
    font-weight: bold;
    text-transform: uppercase;
}
.seats_won {
    width: 100%;
    text-align: left;
    margin-top: 3px;
    display: block;
    clear: both;
    color: #000;
    font-size: 98%;
}
.seats_won span {
    text-align: center;
    font-weight: bold;
    font-size: 110%;
}
.seats_contested {
    width: 100%;
    text-align: left;
    display: block;
    clear: both;
    color: #000;
    font-size: 98%;
}