@charset "UTF-8";
@import "./global.scss";
.colWork {
    display: flex;
    width: 90%;
    margin: auto;
    column-gap: 1rem;
    margin-bottom: 1rem;
    padding: 1.4rem;
}

.colFlex {
    row-gap: 2rem;
    column-gap: 1rem;
    color: #444;
    -webkit-column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 1rem;
    padding: 0px;
    width: 100%;
}

.col2 {
    row-gap: 1rem;
    column-gap: 2rem;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.col3 {
    row-gap: 2rem;
    column-gap: 2rem;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
}
.col32 {
    row-gap: 2rem;
    column-gap: 2rem;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    width: 100%;
}

.col21 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col4 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col5 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}