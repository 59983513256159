@import "../../assets/css/global.scss";

.container {
  background-color: $greyLight;
  height: 100vh;
  position: relative;
  width: 100%;
}

.leftContainer {
  height: 100%;
  left: 0;
}

.centerContainer {
  position: absolute;
  top: 5%;
  width: 100%;
}

.content {
  display: flex;
  justify-content: center;
}
.logo {
  width: 25%;
}

.saffronBackground {
  height: 100%;
  position: absolute;
  width: 37%;
}

.logoImage {
  position: absolute;
  width: 225px;
  top: 0;
}

.personImage {
  width: 35%;
  height: auto;
  position: absolute;
  bottom: 0;
}

.centerForm {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.form {
  position: relative;
  background: 0 0;
  text-align: center;
  padding: 30px 15px;
  border-radius: 10px;
  z-index: 2;
  width: 450px;
}
