.modalHeader {
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding: 0.7rem 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d0d0d0;
    position: fixed;
    background: white;
    width: 89.85%;
    z-index: 9;
}
.modalSmall {
    width: 40.15%;
    // border-bottom: none;
}
.head {
    font-size: 1.5rem;
    font-weight: 600;
}
.cross {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.modalBody {
    padding: 0rem 2rem;
    width: 100%;
    overflow-y: auto;
    padding-top: 70px;
}
