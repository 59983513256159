@import "../../assets/css/global.scss";

.inputSection {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
}

.formInput {
    border: 2px solid $greyLight;
    border-radius: 5px;
    height: 45px;
    width: 100%;
    padding-left: 20px;
    background-color: white;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;

    &::placeholder {
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
        color: grey;
    }
    &:disabled {
        color: grey;
        opacity: 0.7;
    }
    &:disabled::placeholder {
        color: grey;
        opacity: 0.7;
    }

    &.small {
        height: 30px;
    }
}

.inputLabelText {
    width: max-content;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    color: darken($grey, 3%);
    margin-bottom: 5px;
}

.checkbox {
    accent-color: $accent;
    width: 18px;
    height: 18px;
    border: 2px solid darken($greyLight, 10%);
}

.radio {
    @include flex-center;
    justify-content: space-around;
}

.singleRadio {
    @include flex-center;
}
.checkboxWrapper {
    @include flex-center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    margin: 0 0 15px;
}
.checkboxWrapper span {
    font-size: 16px;
    font-weight: 500;
    color: $primary;
}

.errorText {
    color: $error;
    width: 100%;
    text-align: left;
    font-weight: 500;
    line-height: 0.5;
    font-size: 15px;
    margin-top: 9px;
    margin-left: 3px;
    font-size: 11px;
    font-weight: 600;
    &:hover {
        cursor: default;
    }
}
