@import "../../../assets/css/global.scss";

.title {
    font-size: 25px;
    font-weight: 700;
    color: $primary;
    @include flex-center;
    gap: 15px;
    width: 100%;
    justify-content: center;

    & span {
        @include box(75px, 5px);
        background-color: $secondary;
    }
}

.colWork {
    display: flex;
    width: 90%;
    margin: auto;
    column-gap: 1rem;
    margin-bottom: 1rem;
    padding: 1.4rem;
}

.colFlex {
    row-gap: 2rem;
    column-gap: 1rem;
    color: #444;
    -webkit-column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 1rem;
    padding: 0px;
    width: 100%;
}

.col1 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col2 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col3 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}
.col32 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col21 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col4 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.col5 {
    row-gap: 2rem;
    column-gap: 2rem;
    color: #444;
    -webkit-column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
}

.graphTitle {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 1rem;
}

.pie {
    min-height: 240px;
}

.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 700;
}

.karyakartaPie {
    min-height: 410px;
}

.bar {
    min-height: 304px;
}

.barGraph {
    width: 50%;
    margin: auto;
    margin-bottom: 1rem;
}

//Graph
.faceBox {
    @include flex-center;
    flex-direction: column;
}
.faceImg {
    width: 100px;
}
.faceTitle {
    font-size: 2rem;
    font-weight: 700;
}

.graphTitleText {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
}
