.title {
    clear: both;
    font-size: 18px;
    font-weight: 600;
    color: #ff4800;
    background-color: none;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
}
