.infoCard {
    background: #e4f4e8;
    padding: 10px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 50px;
}

.name {
    text-align: center;
    font-size: 1.2rem;
    color: #7a7a7a;
    font-weight: 700;
}
