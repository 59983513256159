@import "../../../assets/css/global.scss";

.container {
  width: 100%;
  height: 40px;
  background-color: $secondary;
  @include flex-center;
}

.navWrapper,
.linksWrapper {
  height: 40px;
}

.navWrapper {
  padding: 10px 0px;
  width: 80%;
  @include flex-center;
  justify-content: space-between;
}
.linksWrapper {
  display: grid;
  grid-auto-flow: column;
  width: 85%;
  height: 40px;
  align-items: center;
}

.linksWrapperSection {
  display: flex;
  gap: 10px;
  width: 85%;
  height: 40px;
  align-items: center;
}

.links {
  color: $primary;
  font-size: 17px;
  font-weight: 600;
  @include flex-center;
  height: 100%;
  transition: $animate;

  &:hover {
    color: $white;
    transition: $animate;
    cursor: pointer;
    // background-color: $primary;
  }

  &.active {
    color: $secondaryLight;
    transition: $animate;
    background-color: $primary;
  }
}

.btnWrapper {
  @include flex-center;
  gap: 5px;
  width: 35%;
}

.loginBtn {
  @include button-primary-dark;
  height: 35px;
  margin-top: 2px;
  padding: 8px 20px;
  font-family: "Poppins";
  transition: $animate;
  font-size: 15px;
  font-weight: 500;
  @include flex-center;
  gap: 10px;

  &:hover {
    @include light-hover;
  }

  &:active {
    @include light-active;
  }
}
