@import "../../assets/css/global.scss";

.secondaryBtn {
    @include button-secondary;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Poppins";

    &:hover {
        @include dark-hover;
    }
    &:active {
        @include dark-active;
    }
    &.small {
        padding: 4px 7px;
        font-size: 12px;
        font-weight: 600;
        height: auto;
    }
    &.auto {
        width: auto;
        padding: 10px 30px;
    }
}
