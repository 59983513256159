.box_matter_container {
    width: 95%;
    text-transform: uppercase;
    margin: 5px auto;
    display: block;
    clear: both;
    height: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}
.result {
    display: flex;
    width: 100%;
}
.partyData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Winner_box {
    width: 49%;
    float: left;
    margin-right: 2%;
    font-size: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(to top, #ffffff, #ffffff);
    box-shadow: 1px 1px 10px #999;
}
.winner_head {
    background: #999;
    line-height: 25px;
    text-align: center;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 1px 1px 5px #ccc;
}
.result_party_symbol {
    width: 43px;
    height: 43px;
    margin: 3% 4% 3% 3%;
    border: 0px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    float: left;
    padding: 1%;
    background: none;
}
.result_party_symbol img {
    width: 35px;
    padding-top: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}
.votes_text {
    color: #000;
    display: block;
    clear: right;
    text-align: left;
    margin-top: 8px;
}
.votes {
    font-size: 85%;
}
.votes span {
    font-weight: 400;
    color: #666;
}
.winning_margin {
    display: block;
    float: left;
    text-align: center;
    width: 100%;
    text-transform: none;
    clear: both;
    margin: 0px auto;
    margin-top: 25px;
    background: #f1f1f1;
    padding: 3px 0px 3px 0px;
}
.winning_margin span {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.polarity {
    width: 100%;
    display: block;
    float: left;
    clear: both;
    margin: 0px auto;
    margin-top: 20px;
    text-transform: none;
    text-align: center;
}

.polarity_left {
    width: 25%;
    float: left;
}

.polarity img {
    width: 60%;
    max-height: 90px;
}

.polarity_middle {
    width: 45%;
    float: left;
    margin: 0 auto;
    margin-left: 2%;
    margin-right: 2%;
}
.polarity_head {
    background: #999;
    display: block;
    clear: both;
    line-height: 25px;
    text-align: center;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 1px 1px 5px #ccc;
    text-transform: none;
}

.polarity_box {
    width: 100%;
    float: left;
    clear: both;
    padding: 12px 0px 12px 0px;
    background: #f1f1f1;
    background: radial-gradient(#fff, #f1f1f1);
    box-shadow: 1px 1px 10px #999;
}
.polarity_right {
    width: 25%;
    height: 70px;
    float: left;
}
.polarity img {
    width: 60%;
    max-height: 90px;
}
