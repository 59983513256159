@import "../../assets/css/common.scss";
@import "../../assets/css/global.scss";

.section {
    width: 100%;
    margin-bottom: 1rem;
}
.createBtn {
    display: flex;
    align-items: center;
    justify-content: end;
}
.center{
    @include flex-center;
}
