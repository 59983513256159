.cls-a {
    fill: #e8e8e8;
}

.cls-a:hover {
    fill: red;
}

.cls-a:active {
    fill: red;
}

.tippy-tooltip,
.tippy-box {
    padding: -10px !important;
}

.no-data {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.fa {
    cursor: pointer;
}

.whatsapp {
    color: #3dd366;
}

.fa-sms {
    color: #f80f0fc2;
}

.fa-phone {
    color: #185ed6e6;
}

.notiflix-loading {
    z-index: 99999 !important;
}

polygon:focus {
    outline: none !important;
}

.actions {
    display: flex;
}

.cross {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 1.5rem;
    color: red;
    font-weight: bolder;
    font-size: 20px;
    cursor: pointer;
}

.fa-add {
    display: flex;
    justify-content: end;
    margin-left: 10px;
    margin-top: 0.5rem;
    color: green;
    font-weight: bolder;
    font-size: 20px;
    cursor: pointer;
}
.page-link:focus {
    box-shadow: 0 0 0 0.1rem #a0a0a040 !important;
}
path:focus {
    border: 0px solid red;
    outline: none;
}
ul.pagination li {
    width: auto !important;
}
.fa-toggle-on, .fa-toggle-off {
    right: 2rem;
    margin-top: 10px;
    position: absolute;
}
