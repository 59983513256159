.infoCard {
    background: #ff073a20;
    padding: 7px 20px;
    border-radius: 10px;
}

.singleRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.value {
    font-size: 0.8rem;
    font-weight: 600;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}

.title {
    font-size: 0.7rem;
    text-align: center;
}
.mobile{
    font-size: 0.7rem;
    text-align: center;
}
