@import "../../assets/css/global.scss";
.tabContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.tabs {
    width: 100%;
    margin: 0 auto;
    align-items: flex-start;
}

.tabList {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 0;
    margin: 0;
}

.tabPanelContainer {
    width: 100%;
}

.tab {
    @include button-primary-transparent;
    border: 2px solid $greyLight;
    height: 40px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    color: $primaryDark;
    @include flex-center;
    justify-content: flex-start;
    text-align: left;
    border-radius: 0px;
    width: auto;
    line-height: 1.25;
    &:hover {
        cursor: pointer;
    }
}

.selectedTab {
    @include button-primary-dark;
    height: 40px;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 14px;
    @include flex-center;
    border-radius: 0px;
    justify-content: flex-start;
    text-align: left;
    line-height: 1.25;
    width: auto;

    &:hover {
        cursor: pointer;
    }
}
.tabPanel {
    display: flex;
    width: 100%;
    margin: 0 auto;
}
