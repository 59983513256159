@import "../constituency/styles.module.scss";

.singleBox{
    display: inline-block;
    min-height: 335px;
    height: auto;
    vertical-align: top;
    font-size: 14px;
    background: #f1f1f1;
    margin: 0% .3% 10px .3%;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
    text-align: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.singleHead{
    display: block;
    clear: both;
    font-size: 18px;
    font-weight: 600;
    color: #ff4800;
    background-color: none;
    text-align: center;
    padding: 15px 0px 10px 0px;
    width: 100%;
    margin: 0px 0px 15px 0px;
    border-bottom: 1px solid #ccc;
    text-transform: uppercase;
}
.electFeature{
    padding: 0px 10px;
}
.elect_feature_left {
    width: 53%;
    float: left;
    margin-right: 4%;
}
.male {
    width: 50%;
    height: 50px;
    float: left;
    margin: 20px 0px 30px 0px;
}
.male img {
    height: 50px;
    display: block;
    clear: both;
    margin: 0px auto;
}

.male_graph {
    width: 100%;
    font-weight: 400;
    font-size: 110%;
    line-height: 25px;
    float: left;
    background: #999;
    color: #fff;
}
.normal_text {
    width: 100%;
    text-align: center;
    margin: 0px auto;
    display: block;
    clear: both;
}
.elect_feature_right {
    width: 43%;
    float: right;
    background: none;
}
.votes_box {
    width: 100%;
    float: left;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    height: auto;
    clear: both;
    display: block;
    background: #fff;
}
.votes_icon {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    vertical-align: baseline;
    background: #999;
    margin: 5px auto;
    margin-bottom: 10px;
}

.votes_icon img {
    width: 60%;
    padding: 5px 0px;
    display: inline-block;
}
.normal_text {
    width: 100%;
    text-align: center;
    margin: 0px auto;
    display: block;
    clear: both;
}
.polling_stations {
    width: auto;
    display: inline-block;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background: #999;
    margin: 5px auto;
    margin-bottom: 10px;
    color: #fff;
    line-height: 25px;
    font-size: 120%;
    padding: 5px 10px 5px 10px;
}
.voter_turnout_text {
    display: block;
    clear: both;
    width: 100%;
    float: left;
    letter-spacing: 3px;
    margin: 15px 0px 3px 0px;
    color: #f26054;
}
.voter_turnout {
    width: 70%;
    float: left;
    line-height: 25px;
    background: #f26054;
    color: #fff;
    font-size: 110%;
}
.voter_turnout_blank {
    width: 30%;
    float: left;
    background: #ccc;
    line-height: 25px;
}
.poll_date {
    width: 100%;
    text-align: center;
    margin: 0px auto;
    display: block;
    clear: both;
    font-size: 85%;
    margin-top: 40px;
}
.chartDiv{
    width: 60%;
    margin: auto;
}
.name{
    font-size: 18px;
    background: #f1f1f1;
    border-bottom: 2px solid #ccc;
    text-align: center;
    -webkit-border-radius: 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    padding: 10px;
    font-weight: 700;
}